<template>
  <div data-cy="checkout-cart-summary" class="CheckoutCartSummary">
    <h2 class="text-left font-bold text-1.5xl">
      {{ $t('products') }}
    </h2>
    <p class="text-left text-sm text-system-gray mt-1">{{ $t('productsCount', { count }) }}</p>

    <ul class="CheckoutCartSummary__List mt-6">
      <li
        v-for="{ id, quantity, name, totalDiscount, totalPrice, image, oldPrice, stock, giftBoxMessage, url } in items"
        :key="id"
      >
        <div class="flex items-center space-x-6">
          <AppLink v-if="!isOrderSummary" :to="`/${url}`" class="CartItem__image bg-primary-100 relative">
            <picture>
              <img :src="image?.src || ''" :alt="image?.alt || ''" />
            </picture>
          </AppLink>

          <div v-else class="CartItem__image bg-primary-100 relative">
            <picture>
              <img v-if="image" :src="image.src" :alt="image.alt" />
            </picture>
          </div>

          <div class="flex justify-between w-full">
            <div class="flex flex-col w-full">
              <AppLink :to="`/${url}`" class="CartItem__name justify-self-start">
                <span v-if="brand" data-attr="cartItem__brandName__txt" class="block font-semibold text-sm mb-1">{{
                  brand
                }}</span>
                <span data-attr="cartItem__productName__txt" class="text-sm md:text-base">{{ name }}</span>
                <span v-if="!stock && !isOrderSummary" class="block mt-1 text-sm text-red-500">{{
                  $t('outOfStockLabel')
                }}</span>
                <span v-if="stock && stock < quantity" class="block mt-1 text-sm text-primary-500">{{
                  $t('qtyUnavailableLabel')
                }}</span>
              </AppLink>
              <div class="w-full mt-2 flex gap-x-4">
                <div class="w-full flex items-center justify-between">
                  <p class="flex items-center gap-x-1.5 text-tiny">
                    <span class="capitalize text-gray-500">{{ $t('qty') }}.</span>
                    <span class="font-semibold">{{ quantity }}</span>
                  </p>
                  <div class="flex items-center space-x-5">
                    <p v-if="Number(oldPrice ?? 0) * quantity !== Number(totalPrice ?? 0) - Number(totalDiscount ?? 0)">
                      <Money class="text-tiny line-through text-gray-500" :value="oldPrice ?? 0 * quantity" />
                    </p>
                    <p class="font-bold text-sm">
                      <Money :value="Number(totalPrice ?? 0) - Number(totalDiscount ?? 0)" />
                    </p>
                  </div>
                </div>
              </div>

              <section
                v-if="giftBoxMessage && giftBoxMessage !== 'N/A'"
                class="[ flex flex-col space-y-2.5 ] text-sm bg-primary-2-30 [ p-3.5 mt-4 ] col-span-2"
              >
                <header class="text-sm text-gray-500 mr-2">{{ $t('customMessage') }}</header>

                <div class="flex items-center oneLine font-bold">
                  <p class="">"{{ giftBoxMessage }}"</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { CartItem } from '@robustastudio/e-commerce/dist/runtime';

defineComponent({
  name: 'CartSummary',
});

const { t: $t } = useI18n({ useScope: 'local' });

defineProps({
  items: {
    type: Array as PropType<CartItem[] | []>,
    default: () => [],
  },
  count: {
    type: Number,
    default: 0,
  },
  isOrderSummary: {
    type: Boolean,
    default: false,
  },
});
</script>

<i18n>
{
  "en": {
    "products": "Products In Cart ",
    "qty": "Qty",
    "purchaseItems": "Purchase Items",
    "productsCount": "You have {count} product in your order | You have {count} products in your order",
    "customMessage": "CUSTOM MESSAGE",
    "qtyUnavailableLabel": "Quantity Unavailable",
    "outOfStockLabel": "Out of Stock"
  },
  "ar": {
    "products": "المنتجات",
    "purchaseItems": "المنتجات المشتراه",
    "productsCount": "لديك {count} منتج فى طلبك ",
    "qty": "الكمية",
    "customMessage": "رسالة مخصصة",
    "outOfStockLabel": "نفذت الكمية",
    "qtyUnavailableLabel": "الكمية غير متوفرة"
  }
}
</i18n>

<style lang="postcss" scoped>
.CheckoutCartSummary {
  @apply p-6 border border-t-0 border-primary-1-15;

  &__List {
    li + li {
      @apply border-t border-primary-1-15;
      margin-top: 23px;
      margin-block-start: 26px;
      padding-block-start: 24px;
    }
  }
}

@screen lg {
  .CheckoutCartSummary {
    @apply px-7;
    &::before {
      content: unset;
    }
  }
}

.CartItem {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  &__image {
    @apply flex-shrink-0 bg-white;
    width: 60px;
    height: 88px;

    img {
      @apply object-contain w-full h-full;
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@screen md {
  .CartItem {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
}
.oneline {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
